@import 'mixins';
@import 'loader';
@import 'form-wizard';
@import 'skin';

#wrapper-body {
  overflow: hidden;
  display: none;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
  min-height: 100vh !important;
}

#app-body {
  .auth-loader {
    @extend .tgt-loader;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 45%;
    left: calc(50% - 40px);
  }
  &.loaded {
    .auth-loader {
      display: none;
    }
    #wrapper-body {
      display: block;
    }
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.select2-container {
  width: 100% !important;
}
.checkbox label,
.radio label {
  padding-left: 0px;
}
td.rbc-agenda-event-cell .btn.btn-sm.btn-primary {
  float: right;
}
.Bikes_heading {
  margin-top: 10px;
}
p.Bikes {
  margin: 0px !important;
}
.form-control.photo-upload {
  height: auto;
}
button.btn.btn-primary.btn-sm.filteraction {
  margin-top: 26px;
}
.tgt-form.filter.contract [type='submit'] {
  margin-top: 25px !important;
}
.main-header .logo {
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-mini,
  .logo-lg,
  svg,
  img {
    height: 40px;
  }

  img {
    vertical-align: unset;
  }
}
.content-header {
  > .breadcrumb {
    float: none !important;
    position: static !important;
  }
}
main.content {
  .box-header {
    .box-title {
      font-size: 24px;
    }
  }
  .dataTables_wrapper {
    border: 1px solid #ececec;
    padding: 10px;
    .dataTables_scrollHeadInner {
      min-width: 100% !important;
    }
  }
}

.main-sidebar {
  overflow: auto;
  height: 100vh;
}

.sidebar-menu {
  li.separate {
    padding: 5px 15px 5px 15px;
    a {
      background: #ecf0f5;
      opacity: 0.4;
      padding: 1px;
    }
  }
}
.thumbnail {
  display: inline-block;
}

.box {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

@media (min-height: 800px) {
  html,
  body {
    overflow: hidden;
    height: 100vh;
  }

  #wrapper-body {
    height: 100vh !important;
    display: flex !important;
    flex-direction: column;
    .content-wrapper {
      flex: 1;
      display: flex;
      overflow-x: hidden;
      overflow-y: auto;
      min-height: unset !important;

      .content {
        flex: 1;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }

  .box.--contains-table {
    max-height: calc(100% - 15px);

    .box-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .tgt-table {
        flex: 1;
        overflow: hidden;
      }
    }
  }
}

#user-login-name {
  .fa {
    margin-left: 3px;
  }
}
/* Datatable */
.tgt-datatable {
  min-width: 100% !important;
  thead {
    .Action {
      text-align: center;
    }
  }
}

.box-action {
  text-align: center;
  width: 100%;

  & > .action-container,
  & > a,
  & > button,
  & > .btn {
    margin-right: 5px;
    float: left;
  }

  .action-container {
    position: relative;

    .action-menu {
      right: 0;
      margin-top: 0;
    }
  }

  .btn-flat {
    background: none;
    border: none;
  }

  .btn-action-menu {
    padding: 0;
  }
}

//
.tgt-modal {
  form {
    .form-footer {
      text-align: right;
      padding-top: 15px;
      border-top: 1px solid #f4f4f4;
      button {
        margin-left: 10px;
      }
    }
  }
}

//
.box-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  img.thumbnail {
    max-width: 100%;
    margin-bottom: 5px;
  }
}

//
.alert {
  a {
    text-decoration: none !important;
  }
}

.tgt-form {
  padding: 15px;
  border: 1px solid #e6e6e6;
  &.filter {
    border-bottom: none;
    padding: 5px;
    margin: 0px;
    [type='submit'] {
      margin-top: 20px;
    }
    .form-group {
      margin-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
      & > label {
        font-weight: 600;
      }
    }
    &.contract {
      [type='submit'] {
        margin-top: 5px;
        @include media-max(1199px) {
          margin-top: 28px;
        }
        @include media-max(767px) {
          margin-top: 5px;
        }
      }
    }
  }
}

.tgt-tableview {
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
      &:first-child {
        td {
          border-top: none;
        }
      }
      &:last-child {
        td {
          border-bottom: 1px solid #f4f4f4;
        }
      }
    }
  }
  .thumbnail {
    margin-bottom: 0px;
  }
}

.checkout-request-bike {
  .tgt-form {
    margin-bottom: 15px;
    .fclose {
      display: none;
    }
    .form-footer {
      text-align: left;
      border: none;
      padding-top: 0px;
      button {
        margin: 0px;
      }
    }
  }
}

.board.contract {
  .tgt-form {
    .form-footer {
      text-align: right;
    }
  }
}
.contract-checkout {
  .box-header {
    text-align: center;
  }
  &.new {
    .board.contract .nav-tabs {
      .liner {
        width: 50%;
      }
      & > li {
        width: calc(100% / 2);
      }
    }
    .nav {
      li.return,
      li.photos {
        display: none;
      }
    }
  }
  &.finish {
    .board.contract .nav-tabs {
      .liner {
        width: 50%;
      }
      & > li {
        width: calc(100% / 2);
      }
    }
    .nav {
      li.info,
      li.payment {
        display: none;
      }
    }
  }
  .box-f-bike {
    display: block;
    margin-bottom: 10px;
    .select2-container {
      min-width: 250px;
    }
    .select2-selection.select2-selection--single {
      padding: 1px 15px;
      height: 25px;
      .select2-selection__arrow {
        height: 20px;
      }
    }
  }
}

.make_contract {
  text-align: center;
  cursor: pointer;
  width: 100px;
  font-weight: bold;
  font-size: 10px;
  background: #3174ad;
  color: #ffffff;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: absolute;
}

.end_contract {
  text-align: center;
  cursor: pointer;
  width: 100px;
  font-weight: bold;
  font-size: 10px;
  background: #057985;
  color: #ffffff;
  border-radius: 3px;
  padding: 5px 10px;
  margin-left: 5px;
}

form.contract_request {
  float: left;
}

.contract_photo {
  display: none;
}

.rbc-month-view .Bikes_heading,
.rbc-month-view .Bikes {
  margin-top: 10px;
  display: none;
}
.rbc-month-view .Bikes_heading,
.rbc-month-view .Bikes {
  margin-top: 10px;
  display: none;
}
.rbc-agenda-view table.rbc-agenda-table tr th:nth-child(2),
.rbc-agenda-view table.rbc-agenda-table tr td.rbc-agenda-time-cell {
  display: none;
}

p.Bikes {
  font-size: 10px;
  min-height: 20px;
}

.rbc-date-cell:hover .make_contract {
  display: block !important;
  visibility: visible;
  cursor: pointer !important;
}

.rbc-date-cell.rbc-off-range:hover .make_contract {
  display: none !important;
  visibility: visible;
  cursor: pointer !important;
}

td.localName .btn.btn-sm.btn-primary {
  float: right;
  width: 85px;
}
i.fa.fa-check {
  color: #058605;
}
.allsteps {
  text-align: center;
}
form.tgt-form.filters_form .col-md-12.form-footer {
  display: none;
}
.notes {
  white-space: pre;
}
button.btn.btn-primary.btn-sm.clearfilter {
  margin-left: 15px;
  margin-top: 20px;
}
.cash_select {
  margin-left: 10px;
}
.maintenance-checkout {
  .box-header {
    text-align: center;
  }
  .nav-tabs > li {
    width: 50%;
  }
  .liner {
    width: 50%;
  }
  .tgt-form {
    .form-footer {
      text-align: right;
    }
  }
}

//REPORT
.report-page {
  .box-header {
    display: none;
  }
}
.bike-location-report {
  text-align: center;
  .tgt-loader {
    margin: 5% 0;
  }
  .column-block {
    width: 220px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    // border:1px solid #ccc;
    vertical-align: top;
    text-align: left;
    h2 {
      margin: 0;
      text-align: center;
      font-size: 24px;
      color: #fff;
      padding: 5px;
      background-color: #00cd00;
    }
  }
  .row-block {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    h4 {
      margin: 5px 0;
      margin: 0;
      background: #d4d4d4;
      a {
        padding: 5px;
        color: #222;
        display: block;
      }
      border-left: 5px solid #555;
    }
    & > div > table {
      margin: 0;
      background: #fff;
      border: 1px solid #d4d4d4;
      border-top: none;
    }
    .bike-item {
      a {
        display: block;
      }
      .bike-add-info {
        margin: 0 10px;
        p {
          margin: 0;
        }
      }
      .btn-sm-view {
        display: inline-block;
        background: $primary-color;
        color: #fff;
        padding: 1px 8px;
        margin-right: 5px;
        margin-top: 5px;
        font-size: 14px;
        border-radius: 2px;
      }
    }
  }
  [data-toggle='collapse'] {
    &:after {
      font-family: 'Glyphicons Halflings';
      content: '\e114';
      float: right;
      color: #555;
      font-size: 60%;
      margin-top: 4px;
    }
    &.collapsed {
      &:after {
        content: '\e080';
      }
    }
  }
}
.returns-report {
  img.thumbnail {
    margin: 0;
    padding: 2px;
  }
}
.upcoming-rentals-report {
  .table {
    .trhead {
      background: #e6e6e6;
    }
  }
}

div#contract-info .Collapsible {
  position: relative;
  padding-top: 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  border: #f1eded solid 1px;
  margin-bottom: 10px;
  overflow: hidden;
  padding-bottom: 0px !important;
  border-bottom: #6f6f6f solid;
}

span.Collapsible__trigger {
  width: 100% !important;
  float: left;
}
.Collapsible__contentOuter {
  float: left;
  margin-top: 10px;
}

.form-group.select2jsajax .btn.btn-sm.btn-success {
  float: right;
  margin-top: 4px;
}
.contract_request_header {
  width: 100%;
  overflow: hidden;
}
.contact_req_select {
  float: left;
}
.contact_req_button {
  padding-top: 26px;
  padding-left: 10px !important;
  float: left;
}
.col-sm-4.2.photo_content {
  position: relative;
  margin-bottom: 10px;
}
.pull-right.Photo_main {
  position: absolute;
  right: 13px;
  bottom: -40px;
  padding-top: 20px;
  z-index: 9999;
}
#mdl-bikes-form .form-footer {
  margin-top: 50px;
}

span.eq_select {
  border: #dedede solid 1px;
  border-radius: 5px;
  text-align: center;
  margin-right: 10px;
  padding: 5px 15px 5px 15px;
  float: left;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 13px;
}
.equipment_purchase_content .form-group.checkbox,
.equipment_rental_content .form-group.checkbox {
  float: left;
  width: 100%;
}

.close2:focus,
.close2:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.form-group.col-lg-2.col-sm-3.checkinrow {
  padding-top: 6px;
}

[title='Add New Customer'] {
  display: none;
  position: absolute;
  z-index: 9999;
  right: 17px;
  top: 97px;
}

.closeForm {
  margin-top: 0px;
  position: absolute;
  right: 10px;
}
.tab-pane.active.EditContract .closeForm,
.tab-pane.active.EditContract .btn.btn-sm.btn-success.AddContract {
  display: none;
}
td {
  max-width: 420px;
}
tr.overdue {
  background: #fbe5e1;
}
div#contract-finish .btn.btn-danger.btn-sm {
  display: none;
}

div#contract-finish .photo_upload [name='img_damage_report_photo_id'] {
  display: none !important;
}
button.btn.btn-sm.btn-success.newContract {
  margin-top: 0px !important;
}
.board.contract .tgt-form .form-footer {
  float: left !important;
}
.clearfilter.BikeCheckin {
  margin-top: 18px !important;
}

.datepicker-days td:first-child {
  background: #dd4b39 !important;
  color: white !important;
}
.datepicker-days td:first-child:hover {
  color: #000 !important;
}
div.form-footer {
  clear: both;
}
.removeborder {
  border: none !important;
}
button.btn.btn-sm.btn-success.checkMotor {
  margin-top: 0px;
}

form.tgt-form.ContratRequest.row.contract {
  max-width: 93%;
  float: left;
}

main.content .dataTables_wrapper {
  border: 1px solid #ececec;
  padding: 10px;
  float: left;
  width: 100%;
}
.right {
  float: right !important;
}
form.tgt-form.Contract.Workshop.filter.col-sm-10 {
  padding-left: 12px;
}

.Workshop.filter.col-sm-2 button.btn.btn-primary.btn-sm.clearfilter {
  float: right;
  margin-right: 15px;
}
.Workshop button.btn.btn-primary.btn-sm {
  margin-top: 20px !important;
}
.displaynone {
  display: none !important;
}

.table-hover > tbody > tr.overdue:hover {
  background: #fbe5e1 !important;
}

tr.priority {
  background: #dd4b39 !important;
}

.PartsBtn .btn:nth-child(1) {
  margin-right: 10px;
}

.bike-location-report .row-block h4.no_checkin {
  background: #fb9f95;
}

.bike-location-report .row-block .bike-item .btn-sm-view {
  margin-right: 3px;
}

.btn.btn-sm.btn-success.redirectContract,
.btn.btn-sm.btn-success.redirectContract1 {
  display: none;
}

.btn.btn-sm.btn-primary.clear.right {
  margin-top: 5px;
}

.btn.btn-sm.btn-envelope {
  background: #f39c12;
  color: #f4f4f4;
}

.box.report-page .tab-content > .active {
  display: block;
  overflow: auto;
}

main.content .dataTables_wrapper {
  overflow: auto;
}
.tgt-datatable td {
  word-wrap: break-word;
}

.contacted,
.table-hover > tbody > tr.contacted:hover {
  background: #ffc05b !important;
}

.responded,
.table-hover > tbody > tr.responded:hover {
  background: #098009 !important;
  color: #ffffff;
}

.table-hover > tbody > tr.responded a {
  color: #ffffff !important;
}

.btn.btn-sm.btn-success.backbtn {
  margin-bottom: 10px;
}
.btn {
  margin-bottom: 5px;
}

i.fa.fa-check.white {
  color: #ffffff;
}

.btn.btn-sm.btn-success.newCity {
  margin-right: 10px;
}
.col-lg-12.col-sm-12.commmunication-popup-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

h4.field_heading {
  border-bottom: solid 1px;
  padding-bottom: 10px;
  font-weight: bold;
  max-width: 250px;
}
.td_hide {
  display: none;
}

td.hidden_time span {
  display: none;
}

.btn-sm.group-submit {
  margin-top: 23px;
}

div#mdl-bikes-form .modal-body {
  overflow: hidden;
}

.print.align-right {
  width: 100%;
  float: left;
  text-align: right;
  padding-top: 4px;
  position: absolute;
  right: 1%;

  i {
    color: #337ab7;
    cursor: pointer;

    &:hover {
      color: #23527c;
    }
  }
}

h4.print_heading {
  text-align: center;
  padding-top: 45px;
}

p.print_heading_text {
  text-align: center;
}

.bank-info,
.customer-info,
.payment-info,
.payment-accessries-info {
  margin: 0px 25px 0px 25px;
  overflow: hidden;
  padding-top: 25px;
}

.term-info {
  margin: 0px 25px 0px 25px;
  overflow: hidden;
}

p.main_heading {
  font-size: 16px;
  line-height: 0;
}

p.sub_heading {
  font-size: 13px;
  border-bottom: #000 solid;
  padding-bottom: 5px;
  margin-bottom: 25px;
}
.input_heading {
  font-size: 14px;
}
.input_subheading {
  font-size: 13px;
  padding-bottom: 5px;
}
.print-container {
  width: 100%;
  padding-bottom: 100px;
  color: #000;
}

.remove_padding {
  padding: 0px;
}

p.field_value {
  border-bottom: dotted;
  border-width: 1px;
  font-size: 13px;
  text-align: left;
  margin-left: 10px;
  padding: 0px;
  line-height: 2;
  max-width: 220px;
}

.single_line p.field_value {
  padding-top: 20px;
  max-width: 634px;
}

.payment-info p.field_value,
.payment-accessries-info p.field_value {
  float: right;
  width: 75%;
  margin-right: 15px;
}

p.input_heading.total {
  font-size: 15px;
  font-weight: bold;
  text-decoration: underline;
}

.term-info span {
  font-weight: bold;
}

.customer_signature {
  max-width: 350px;
  margin: 0 auto;
  margin-top: 70px;
}

.term-info p.field_value {
  float: right;
  overflow: hidden;
  width: 350px;
  padding-top: 20px;
}

span.active_contracts {
  float: left;
  margin-bottom: 5px;
  width: 100%;
}

span.active_contracts .Contract {
  margin-right: 20px;
}

.Graph_container {
  margin-bottom: 50px;
  max-height: 300px;
}

.Graph_container h4 {
  text-decoration: underline;
}

td.mannual_charge i.fa.fa-check.white {
  color: #058605;
}

.btn.btn-sm.btn-payment {
  background-color: #337ab7;
  color: #fff;
}

.btn.btn-sm.btn-btn.btn-sm.btn-note {
  background: #008d4c;
  color: #ffff;
}

input[name='cost_duration_extended'],
input[name='cost_duration_base'] {
  max-width: 255px;
}

.board.contract-request .nav-tabs > li {
  width: calc(100% / 4) !important;
}

.board.contractTab-request .nav-tabs > li {
  width: calc(100% / 8) !important;
}

ul.action-menu {
  list-style-type: none;
  padding: 0;
  border: #e4e2e2 solid 1px;
  border-radius: 2px;
  color: gray;
  background-color: #fff;
  position: absolute;
  width: 210px;
  right: 30px;
  margin-top: 35px;
  z-index: 999;
  text-align: left;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease;

  .btn {
    width: 40px;
    margin-right: 10px;
    margin-bottom: 0;
  }

  li {
    padding: 6px 15px;
    border-bottom: 1px solid #e6dcdc;
    cursor: pointer;

    &:hover {
      background: #e4e4e4;
    }
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

.box.contracts .tgt-table-container {
  min-height: 600px;
}

i.fa.fa-ellipsis-v {
  font-size: 30px;
  border-radius: 50%;
  color: gray;
  margin-left: 20px;
}

div.bike_column th,
div.bike_column td {
  padding: 6px 10px;
  border: #dedede solid 1px;
  border-top: none;
  min-width: 435px;
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 999;
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

#crequest-transport .hidden {
  display: inline-block !important;
  width: 50%;
  overflow: hidden;
  float: left;
}

#crequest-transport .hidden div {
  display: none;
}

#crequest-transport .form-group.hidden {
  display: none !important;
}

#crequestTab-transport .damage_waiver_content {
  float: right;
}

.errorBarsSelect {
  max-width: 250px;
}

.btn.btn-sm.btn-btn.btn-sm.btn-note:hover {
  background: #006d3b;
}

.btn.btn-sm.btn-envelope:hover {
  background: #cc8009;
}

@keyframes chartfix {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    transform: scale(1.01);
    -webkit-transform: scale(1.01);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.panel {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
  animation: chartfix 5s;
  -webkit-animation: chartfix 5s;
}

.stock-prediction-chart {
  &.interactive-legend .recharts-legend-item {
    cursor: pointer;
  }

  .recharts-legend-item .disabled {
    text-decoration: line-through;
  }

  .recharts-tooltip-wrapper {
    background-color: rgba(white, 0.7);
    padding: 5px;

    table {
      th,
      td {
        padding: 0 2px;
        font-size: 12px;
      }

      th {
        font-size: 10px;
        &[colspan='2'] {
          text-align: center;
        }
      }
    }

    @supports (backdrop-filter: blur(4px)) {
      backdrop-filter: blur(4px);
    }
  }

  .recharts-errorBar line:first-child,
  .recharts-errorBar line:last-child {
    stroke-dasharray: none;
  }
}

.bike-lookup {
  textarea {
    max-width: 100%;
    min-width: 100%;
    height: 200px;
    margin-bottom: 10px;
  }

  .table > tbody > tr > td {
    vertical-align: middle;

    .btn:not(:first-child) {
      margin-left: 5px;
    }
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn.loading {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
  pointer-events: none;

  .fa {
    @extend .tgt-loader;
    border: 2px solid #f1f1f1;
    border-top: 2px solid transparent;
    width: 11px;
    height: 11px;
    &:before {
      content: '';
    }
  }

  &.btn-default .fa {
    border: 2px solid #555;
    border-top: 2px solid transparent;
  }
}

.text-bold {
  font-weight: bold;
}

.icheck-like label {
  position: relative;
  padding-left: 21px;

  input {
    display: none;
  }

  .styled-checkbox {
    position: absolute;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #dadada;
    transition: border-color 0.25s;

    &:before,
    &:after {
      font: normal normal normal 14px/1 FontAwesome;
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      padding: 1px;
      color: transparent;
      transition: 0.25s;
      transition-property: color, transform;
    }

    &:before {
      content: '\F068';
      font-size: 12px;
      padding: 3px;
    }

    &:after {
      content: '\F00C';
    }
  }

  &:hover
    input:not(:checked):not(:indeterminate):not(:disabled)
    + .styled-checkbox {
    border-color: #78a300;
  }

  input:indeterminate + .styled-checkbox:before,
  input:checked + .styled-checkbox:after {
    color: #78a300;
    transform: rotate(360deg);
  }
}

.icheck-like.disabled label {
  color: #777;
  font-style: italic;
  cursor: not-allowed;

  .styled-checkbox {
    background-color: #eee;
  }
}

.tgt-table {
  border: 1px solid #ececec;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .box-action {
    width: auto;
    position: relative;
  }

  .tgt-table-container {
    flex: 1;
    max-height: 80vh;
    min-height: 39px;
    overflow: auto;
    padding-bottom: 1px;

    table {
      margin-bottom: 0;

      thead th {
        position: sticky;
        top: 0;
        z-index: 1000;
        background-color: white;

        &:before,
        &:after {
          content: '';
          position: absolute;
          height: 2px;
          left: 0;
          right: 0;
          background-color: #f4f4f4;
        }

        &:before {
          top: -2px;
        }

        &:after {
          bottom: -2px;
        }

        &.tgt-table--sortable-column {
          padding-right: 30px;
          cursor: pointer;

          .tgt-table--sort-icon {
            display: contents;

            &:after {
              position: absolute;
              content: '\E150';
              opacity: 0.2;
              transition: opacity 0.25s ease;
              top: 50%;
              transform: translateY(-50%);
              right: 8px;
              font-family: Glyphicons Halflings;
            }
          }

          &[aria-sort='ascending'] .tgt-table--sort-icon:after {
            content: '\E155';
          }

          &[aria-sort='descending'] .tgt-table--sort-icon:after {
            content: '\E156';
          }

          &[aria-sort='ascending'] .tgt-table--sort-icon:after,
          &[aria-sort='descending'] .tgt-table--sort-icon:after {
            opacity: 0.5;
          }

          &:hover .tgt-table--sort-icon {
            &:after {
              opacity: 0.4;
            }

            &[aria-sort='ascending']:after,
            &[aria-sort='descending']:after {
              opacity: 0.7;
            }
          }
        }
      }

      td .form-group {
        margin: 0;
      }
    }
  }

  > .loading {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &:after {
      content: '';
      @extend .tgt-loader;
    }
  }

  .tgt-table--controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .tgt-table--record-count {
      font-style: italic;
      padding-left: 2px;
    }

    .tgt-table--search {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      font-weight: 400;
      margin-bottom: 0;

      input {
        border: 1px solid #eeeeee;
        margin-left: 0.5em;
        height: 30px;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }
}

.sidebar-toggle {
  outline: none;
  border: none;
}

.CalendarDay.CalendarDay__today {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    border: 8px solid #8bc34a;
    right: 0;
    top: 0;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
}

.CalendarDay.CalendarDay__firstDayOfWeek {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    border: 8px solid #dd4b39;
    left: 0;
    top: 0;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
}

textarea.form-control {
  max-width: 100%;
}

.tgt-form.tgt-form--filter {
  border: none;
  padding: 5px 10px;

  .form-group {
    float: left;
    padding: 0;
    max-width: calc(100% - 10px);
    min-width: 100%;

    @media (min-width: 768px) {
      // col-sm-3
      min-width: calc(3 / 12 * 100% - 10px);
      &.wide {
        min-width: 400px;
      }
    }

    @media (min-width: 1200px) {
      // col-lg-2
      min-width: calc(2 / 12 * 100% - 10px);
    }

    & > label {
      font-weight: 600;
    }
  }

  .form-group,
  .tgt-form--filter--action {
    margin: 5px;
  }

  .tgt-form--filter--action {
    float: left;

    @media (min-width: 768px) {
      margin-top: 32px;
    }
  }

  .date-range-picker {
    width: auto;
  }
}

.request-checkout-email-field {
  display: flex;
  align-items: flex-start;

  .form-group {
    flex: 1;
    margin-right: 5px;
  }

  .btn {
    margin-top: 25px;
  }
}

.tgt-form--filter .form-group.date-picker {
  .SingleDatePicker,
  .DateRangePicker {
    z-index: 1001;
  }
}

.form-group.date-picker {
  max-width: 100%;

  .SingleDatePicker,
  .DateRangePicker {
    display: flex;
    align-items: center;

    > div {
      display: contents;

      .SingleDatePickerInput,
      .DateRangePickerInput {
        display: flex;
        align-items: center;
        flex: 1;
        border-radius: 4px;

        &.DateRangePickerInput__showClearDates {
          padding-right: 34px;
        }
      }

      .DateInput {
        width: 88px;
        flex: 1;
        background-color: transparent;

        .DateInput_input {
          padding: 3px 10px;
          background-color: transparent;
          font-size: 14px;
          font-weight: 400;

          &::placeholder {
            font-weight: 200;
          }
        }
      }

      .SingleDatePickerInput_clearDate,
      .DateRangePickerInput_clearDates {
        padding: 0;
        margin: 0;
        right: 2px;
        transform: none;
        top: 2px;
        height: 30px;
        width: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 14px;
        }
      }
    }
  }
}

.react-select-container {
  .react-select__menu {
    z-index: 1001;
  }

  .react-select__control {
    min-height: 34px;
    cursor: pointer;

    .react-select__indicator {
      padding: 4px 8px;
    }
  }
  .react-select__option--is-disabled.react-select__option--is-focused {
    background-color: transparent;
  }
}

.tgt-table td .location-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.nowrap {
  white-space: nowrap;
}

.contract-request-bikes {
  white-space: nowrap;
  margin-bottom: 10px;
  .btn {
    margin-left: 5px;
  }
}

.bike-type-add-form {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .bike-type-add-form--bike-type {
    order: 1;
  }

  .bike-type-add-form--quantity {
    order: 2;
    flex: 1;
  }

  .bike-type-add-form--submit {
    margin-top: 25px;
    margin-left: 5px;
    order: 3;
  }

  .bike-type-add-form--bike-type {
    width: 100%;
  }

  .bike-type-add-form--bike {
    flex: 1;
    width: auto;
  }

  @media (min-width: 768px) {
    flex-wrap: nowrap;

    .bike-type-add-form--quantity {
      flex: none;
      margin-right: 5px;
      order: 1;

      input {
        width: 80px;
      }
    }

    .bike-type-add-form--bike-type {
      width: 300px;
      order: 2;
    }
  }
}

.tgt-form .table td .form-group.checkbox {
  margin: 0;
}

.box.--contains-table .box-body {
  .box-block,
  .tab-content,
  .tab-content > .active {
    display: contents;
  }
}

.login-box {
  border: 1px solid #ccc;

  .login-logo {
    background: #087b83;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100px;
      margin: 10px;
      vertical-align: middle;
    }
  }
}

.box-header.box-subheader {
  padding-bottom: 0;
}
