@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic);


.tgt-loader, #app-body .auth-loader, .btn.loading .fa, .tgt-table > .loading:after {
  border: 5px solid #f1f1f1;
  border-radius: 100%;
  border-top: 5px solid #057985;
  width: 35px;
  height: 35px;
  display: inline-block;
  -webkit-animation: tgt-spin 0.8s linear infinite;
  animation: tgt-spin 0.8s linear infinite; }

@-webkit-keyframes tgt-spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes tgt-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.tab-loader {
  text-align: center;
  margin: 10px 0px;
  padding: 50px 0px; }

/* written by riliwan balogun http://www.facebook.com/riliwan.rabo*/
.board {
  width: 100%;
  margin: 0;
  height: auto;
  background: #fff; }
  .board .nav-tabs {
    position: relative;
    border-color: #e08e0b;
    margin-bottom: 0;
    box-sizing: border-box; }
    .board .nav-tabs a[data-toggle='tab'].disabled {
      cursor: not-allowed; }
  .board.board-flex .nav-tabs {
    display: flex;
    width: 100%; }
    .board.board-flex .nav-tabs > li {
      flex: 1 1;
      display: flex;
      justify-content: center; }
  .board .board-inner > .alert {
    margin-top: 10px;
    margin-bottom: 0; }
  .board p.narrow {
    width: 60%;
    margin: 10px auto; }
  .board .nav-tabs > li:before {
    content: '';
    height: 2px;
    background: #ddd;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 40%;
    z-index: 1; }
  .board .nav-tabs > li:first-of-type:before {
    width: 50%;
    left: 50%; }
  .board .nav-tabs > li:last-of-type:before {
    width: 50%;
    right: 50%; }
  .board .nav-tabs > li:first-of-type:last-of-type:before {
    width: 0; }
  .board .nav-tabs > li > button {
    position: relative;
    margin: 0;
    padding: 0;
    border: none;
    outline: none; }
    .board .nav-tabs > li > button > .round-tabs {
      top: 0; }
  .board .nav-tabs > li.active > button,
  .board .nav-tabs > li.active > button:hover,
  .board .nav-tabs > li.active > button:focus,
  .board .nav-tabs > li.active > a,
  .board .nav-tabs > li.active > a:hover,
  .board .nav-tabs > li.active > a:focus {
    color: #555555;
    cursor: default;
    /* background-color: #ffffff; */
    border: 0;
    border-bottom-color: transparent; }
  .board span.round-tabs {
    width: 70px;
    height: 70px;
    line-height: 70px;
    display: inline-block;
    border-radius: 100px;
    background: white;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 25px;
    color: #ccc;
    border: 2px solid #ccc;
    -webkit-transition: 250ms ease-in-out;
    transition: 250ms ease-in-out;
    -webkit-transition-property: color, background-color, border-color;
    transition-property: color, background-color, border-color; }
  .board li.invalid span.round-tabs {
    border-color: #dd4b39;
    color: #dd4b39; }
  .board li.active span.round-tabs {
    border-color: #e08e0b;
    color: #e08e0b;
    background-color: #fafafa; }
  .board .nav-tabs > li {
    width: 20%; }
  .board .nav-tabs > li:after {
    content: ' ';
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: #e08e0b;
    -webkit-transition: 250ms ease-in-out;
    transition: 250ms ease-in-out;
    -webkit-transition-property: opacity, border-width;
    transition-property: opacity, border-width; }
  .board .nav-tabs > li.active:after {
    opacity: 1;
    border-width: 10px; }
  .board .nav-tabs > li button,
  .board .nav-tabs > li a {
    width: 70px;
    height: 70px;
    margin: 20px auto;
    margin-top: 0px;
    border-radius: 100%;
    padding: 0; }
  .board .nav-tabs > li button:hover,
  .board .nav-tabs > li a:hover {
    background: transparent; }
  .board .tab-pane {
    position: relative;
    padding-top: 10px; }
  .board .tab-content .head {
    font-size: 25px;
    text-transform: uppercase;
    padding-bottom: 10px; }
  .board .btn-outline-rounded {
    padding: 10px 40px;
    margin: 20px 0;
    border: 2px solid transparent;
    border-radius: 25px; }
  .board .btn.green {
    background-color: #5cb85c;
    /*border: 2px solid #5cb85c;*/
    color: #ffffff; }
  @media (max-width: 585px) {
    .board {
      width: 90%;
      height: auto !important; }
      .board span.round-tabs {
        font-size: 16px;
        width: 50px;
        height: 50px;
        line-height: 50px; }
      .board .tab-content .head {
        font-size: 20px; }
      .board .nav-tabs > li button,
      .board .nav-tabs > li a {
        width: 50px;
        height: 50px;
        line-height: 50px; }
      .board .btn-outline-rounded {
        padding: 12px 20px; } }

/*
 * Skin: Green
 * -----------
 */
.tgt-primary-skin .main-header .navbar {
  background-color: #057985; }

.tgt-primary-skin .main-header .navbar .nav > li > a {
  color: #ffffff; }

.tgt-primary-skin .main-header .navbar .nav > li > a:hover,
.tgt-primary-skin .main-header .navbar .nav > li > a:active,
.tgt-primary-skin .main-header .navbar .nav > li > a:focus,
.tgt-primary-skin .main-header .navbar .nav .open > a,
.tgt-primary-skin .main-header .navbar .nav .open > a:hover,
.tgt-primary-skin .main-header .navbar .nav .open > a:focus,
.tgt-primary-skin .main-header .navbar .nav > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6; }

.tgt-primary-skin .main-header .navbar .sidebar-toggle {
  color: #ffffff; }

.tgt-primary-skin .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1); }

.tgt-primary-skin .main-header .navbar .sidebar-toggle {
  color: #fff; }

.tgt-primary-skin .main-header .navbar .sidebar-toggle:hover {
  background-color: #046b75; }

@media (max-width: 767px) {
  .tgt-primary-skin .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1); }
  .tgt-primary-skin .main-header .navbar .dropdown-menu li a {
    color: #fff; }
  .tgt-primary-skin .main-header .navbar .dropdown-menu li a:hover {
    background: #046b75; } }

.tgt-primary-skin .main-header .logo {
  background-color: #046b75;
  color: #ffffff;
  border-bottom: 0 solid transparent; }

.tgt-primary-skin .main-header .logo:hover {
  background-color: #035c65; }

.tgt-primary-skin .main-header li.user-header {
  background-color: #057985; }

.tgt-primary-skin .content-header {
  background: transparent; }

.tgt-primary-skin .wrapper,
.tgt-primary-skin .main-sidebar,
.tgt-primary-skin .left-side {
  background-color: #222d32; }

.tgt-primary-skin .user-panel > .info,
.tgt-primary-skin .user-panel > .info > a {
  color: #fff; }

.tgt-primary-skin .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226; }

.tgt-primary-skin .sidebar-menu > li > a {
  border-left: 3px solid transparent; }

.tgt-primary-skin .sidebar-menu > li:hover > a,
.tgt-primary-skin .sidebar-menu > li.active > a,
.tgt-primary-skin .sidebar-menu > li.menu-open > a {
  color: #ffffff;
  background: #1e282c; }

.tgt-primary-skin .sidebar-menu > li.active > a {
  border-left-color: #057985; }

.tgt-primary-skin .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41; }

.tgt-primary-skin .sidebar a {
  color: #b8c7ce; }

.tgt-primary-skin .sidebar a:hover {
  text-decoration: none; }

.tgt-primary-skin .sidebar-menu .treeview-menu > li > a {
  color: #8aa4af; }

.tgt-primary-skin .sidebar-menu .treeview-menu > li.active > a,
.tgt-primary-skin .sidebar-menu .treeview-menu > li > a:hover {
  color: #ffffff; }

.tgt-primary-skin .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px 10px; }

.tgt-primary-skin .sidebar-form input[type='text'],
.tgt-primary-skin .sidebar-form .btn {
  box-shadow: none;
  background-color: #374850;
  border: 1px solid transparent;
  height: 35px; }

.tgt-primary-skin .sidebar-form input[type='text'] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px; }

.tgt-primary-skin .sidebar-form input[type='text']:focus,
.tgt-primary-skin .sidebar-form input[type='text']:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666; }

.tgt-primary-skin .sidebar-form input[type='text']:focus + .input-group-btn .btn {
  border-left-color: #fff; }

.tgt-primary-skin .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0; }

#wrapper-body {
  overflow: hidden;
  display: none;
  -webkit-animation: fadein 0.5s;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 0.5s;
  min-height: 100vh !important; }

#app-body .auth-loader {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 45%;
  left: calc(50% - 40px); }

#app-body.loaded .auth-loader {
  display: none; }

#app-body.loaded #wrapper-body {
  display: block; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Internet Explorer */

/* Opera < 12.1 */

.select2-container {
  width: 100% !important; }

.checkbox label,
.radio label {
  padding-left: 0px; }

td.rbc-agenda-event-cell .btn.btn-sm.btn-primary {
  float: right; }

.Bikes_heading {
  margin-top: 10px; }

p.Bikes {
  margin: 0px !important; }

.form-control.photo-upload {
  height: auto; }

button.btn.btn-primary.btn-sm.filteraction {
  margin-top: 26px; }

.tgt-form.filter.contract [type='submit'] {
  margin-top: 25px !important; }

.main-header .logo {
  display: flex;
  align-items: center;
  justify-content: center; }
  .main-header .logo .logo-mini,
  .main-header .logo .logo-lg,
  .main-header .logo svg,
  .main-header .logo img {
    height: 40px; }
  .main-header .logo img {
    vertical-align: unset; }

.content-header > .breadcrumb {
  float: none !important;
  position: static !important; }

main.content .box-header .box-title {
  font-size: 24px; }

main.content .dataTables_wrapper {
  border: 1px solid #ececec;
  padding: 10px; }
  main.content .dataTables_wrapper .dataTables_scrollHeadInner {
    min-width: 100% !important; }

.main-sidebar {
  overflow: auto;
  height: 100vh; }

.sidebar-menu li.separate {
  padding: 5px 15px 5px 15px; }
  .sidebar-menu li.separate a {
    background: #ecf0f5;
    opacity: 0.4;
    padding: 1px; }

.thumbnail {
  display: inline-block; }

.box {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column; }

@media (min-height: 800px) {
  html,
  body {
    overflow: hidden;
    height: 100vh; }
  #wrapper-body {
    height: 100vh !important;
    display: flex !important;
    flex-direction: column; }
    #wrapper-body .content-wrapper {
      flex: 1 1;
      display: flex;
      overflow-x: hidden;
      overflow-y: auto;
      min-height: unset !important; }
      #wrapper-body .content-wrapper .content {
        flex: 1 1;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto; }
  .box.--contains-table {
    max-height: calc(100% - 15px); }
    .box.--contains-table .box-body {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      overflow: hidden; }
      .box.--contains-table .box-body .tgt-table {
        flex: 1 1;
        overflow: hidden; } }

#user-login-name .fa {
  margin-left: 3px; }

/* Datatable */
.tgt-datatable {
  min-width: 100% !important; }
  .tgt-datatable thead .Action {
    text-align: center; }

.box-action {
  text-align: center;
  width: 100%; }
  .box-action > .action-container,
  .box-action > a,
  .box-action > button,
  .box-action > .btn {
    margin-right: 5px;
    float: left; }
  .box-action .action-container {
    position: relative; }
    .box-action .action-container .action-menu {
      right: 0;
      margin-top: 0; }
  .box-action .btn-flat {
    background: none;
    border: none; }
  .box-action .btn-action-menu {
    padding: 0; }

.tgt-modal form .form-footer {
  text-align: right;
  padding-top: 15px;
  border-top: 1px solid #f4f4f4; }
  .tgt-modal form .form-footer button {
    margin-left: 10px; }

.box-upload {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .box-upload img.thumbnail {
    max-width: 100%;
    margin-bottom: 5px; }

.alert a {
  text-decoration: none !important; }

.tgt-form {
  padding: 15px;
  border: 1px solid #e6e6e6; }
  .tgt-form.filter {
    border-bottom: none;
    padding: 5px;
    margin: 0px; }
    .tgt-form.filter [type='submit'] {
      margin-top: 20px; }
    .tgt-form.filter .form-group {
      margin-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px; }
      .tgt-form.filter .form-group > label {
        font-weight: 600; }
    .tgt-form.filter.contract [type='submit'] {
      margin-top: 5px; }
      @media only screen and (max-width: 1199px) {
        .tgt-form.filter.contract [type='submit'] {
          margin-top: 28px; } }
      @media only screen and (max-width: 767px) {
        .tgt-form.filter.contract [type='submit'] {
          margin-top: 5px; } }

.tgt-tableview tbody tr td {
  vertical-align: middle; }

.tgt-tableview tbody tr:first-child td {
  border-top: none; }

.tgt-tableview tbody tr:last-child td {
  border-bottom: 1px solid #f4f4f4; }

.tgt-tableview .thumbnail {
  margin-bottom: 0px; }

.checkout-request-bike .tgt-form {
  margin-bottom: 15px; }
  .checkout-request-bike .tgt-form .fclose {
    display: none; }
  .checkout-request-bike .tgt-form .form-footer {
    text-align: left;
    border: none;
    padding-top: 0px; }
    .checkout-request-bike .tgt-form .form-footer button {
      margin: 0px; }

.board.contract .tgt-form .form-footer {
  text-align: right; }

.contract-checkout .box-header {
  text-align: center; }

.contract-checkout.new .board.contract .nav-tabs .liner {
  width: 50%; }

.contract-checkout.new .board.contract .nav-tabs > li {
  width: calc(100% / 2); }

.contract-checkout.new .nav li.return,
.contract-checkout.new .nav li.photos {
  display: none; }

.contract-checkout.finish .board.contract .nav-tabs .liner {
  width: 50%; }

.contract-checkout.finish .board.contract .nav-tabs > li {
  width: calc(100% / 2); }

.contract-checkout.finish .nav li.info,
.contract-checkout.finish .nav li.payment {
  display: none; }

.contract-checkout .box-f-bike {
  display: block;
  margin-bottom: 10px; }
  .contract-checkout .box-f-bike .select2-container {
    min-width: 250px; }
  .contract-checkout .box-f-bike .select2-selection.select2-selection--single {
    padding: 1px 15px;
    height: 25px; }
    .contract-checkout .box-f-bike .select2-selection.select2-selection--single .select2-selection__arrow {
      height: 20px; }

.make_contract {
  text-align: center;
  cursor: pointer;
  width: 100px;
  font-weight: bold;
  font-size: 10px;
  background: #3174ad;
  color: #ffffff;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: absolute; }

.end_contract {
  text-align: center;
  cursor: pointer;
  width: 100px;
  font-weight: bold;
  font-size: 10px;
  background: #057985;
  color: #ffffff;
  border-radius: 3px;
  padding: 5px 10px;
  margin-left: 5px; }

form.contract_request {
  float: left; }

.contract_photo {
  display: none; }

.rbc-month-view .Bikes_heading,
.rbc-month-view .Bikes {
  margin-top: 10px;
  display: none; }

.rbc-month-view .Bikes_heading,
.rbc-month-view .Bikes {
  margin-top: 10px;
  display: none; }

.rbc-agenda-view table.rbc-agenda-table tr th:nth-child(2),
.rbc-agenda-view table.rbc-agenda-table tr td.rbc-agenda-time-cell {
  display: none; }

p.Bikes {
  font-size: 10px;
  min-height: 20px; }

.rbc-date-cell:hover .make_contract {
  display: block !important;
  visibility: visible;
  cursor: pointer !important; }

.rbc-date-cell.rbc-off-range:hover .make_contract {
  display: none !important;
  visibility: visible;
  cursor: pointer !important; }

td.localName .btn.btn-sm.btn-primary {
  float: right;
  width: 85px; }

i.fa.fa-check {
  color: #058605; }

.allsteps {
  text-align: center; }

form.tgt-form.filters_form .col-md-12.form-footer {
  display: none; }

.notes {
  white-space: pre; }

button.btn.btn-primary.btn-sm.clearfilter {
  margin-left: 15px;
  margin-top: 20px; }

.cash_select {
  margin-left: 10px; }

.maintenance-checkout .box-header {
  text-align: center; }

.maintenance-checkout .nav-tabs > li {
  width: 50%; }

.maintenance-checkout .liner {
  width: 50%; }

.maintenance-checkout .tgt-form .form-footer {
  text-align: right; }

.report-page .box-header {
  display: none; }

.bike-location-report {
  text-align: center; }
  .bike-location-report .tgt-loader, .bike-location-report #app-body .auth-loader, #app-body .bike-location-report .auth-loader, .bike-location-report .btn.loading .fa, .btn.loading .bike-location-report .fa, .bike-location-report .tgt-table > .loading:after {
    margin: 5% 0; }
  .bike-location-report .column-block {
    width: 220px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    vertical-align: top;
    text-align: left; }
    .bike-location-report .column-block h2 {
      margin: 0;
      text-align: center;
      font-size: 24px;
      color: #fff;
      padding: 5px;
      background-color: #00cd00; }
  .bike-location-report .row-block {
    margin-bottom: 5px; }
    .bike-location-report .row-block:last-child {
      margin-bottom: 0; }
    .bike-location-report .row-block h4 {
      margin: 5px 0;
      margin: 0;
      background: #d4d4d4;
      border-left: 5px solid #555; }
      .bike-location-report .row-block h4 a {
        padding: 5px;
        color: #222;
        display: block; }
    .bike-location-report .row-block > div > table {
      margin: 0;
      background: #fff;
      border: 1px solid #d4d4d4;
      border-top: none; }
    .bike-location-report .row-block .bike-item a {
      display: block; }
    .bike-location-report .row-block .bike-item .bike-add-info {
      margin: 0 10px; }
      .bike-location-report .row-block .bike-item .bike-add-info p {
        margin: 0; }
    .bike-location-report .row-block .bike-item .btn-sm-view {
      display: inline-block;
      background: #057985;
      color: #fff;
      padding: 1px 8px;
      margin-right: 5px;
      margin-top: 5px;
      font-size: 14px;
      border-radius: 2px; }
  .bike-location-report [data-toggle='collapse']:after {
    font-family: 'Glyphicons Halflings';
    content: '\e114';
    float: right;
    color: #555;
    font-size: 60%;
    margin-top: 4px; }
  .bike-location-report [data-toggle='collapse'].collapsed:after {
    content: '\e080'; }

.returns-report img.thumbnail {
  margin: 0;
  padding: 2px; }

.upcoming-rentals-report .table .trhead {
  background: #e6e6e6; }

div#contract-info .Collapsible {
  position: relative;
  padding-top: 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  border: #f1eded solid 1px;
  margin-bottom: 10px;
  overflow: hidden;
  padding-bottom: 0px !important;
  border-bottom: #6f6f6f solid; }

span.Collapsible__trigger {
  width: 100% !important;
  float: left; }

.Collapsible__contentOuter {
  float: left;
  margin-top: 10px; }

.form-group.select2jsajax .btn.btn-sm.btn-success {
  float: right;
  margin-top: 4px; }

.contract_request_header {
  width: 100%;
  overflow: hidden; }

.contact_req_select {
  float: left; }

.contact_req_button {
  padding-top: 26px;
  padding-left: 10px !important;
  float: left; }

.col-sm-4.2.photo_content {
  position: relative;
  margin-bottom: 10px; }

.pull-right.Photo_main {
  position: absolute;
  right: 13px;
  bottom: -40px;
  padding-top: 20px;
  z-index: 9999; }

#mdl-bikes-form .form-footer {
  margin-top: 50px; }

span.eq_select {
  border: #dedede solid 1px;
  border-radius: 5px;
  text-align: center;
  margin-right: 10px;
  padding: 5px 15px 5px 15px;
  float: left;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 13px; }

.equipment_purchase_content .form-group.checkbox,
.equipment_rental_content .form-group.checkbox {
  float: left;
  width: 100%; }

.close2:focus,
.close2:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5; }

.form-group.col-lg-2.col-sm-3.checkinrow {
  padding-top: 6px; }

[title='Add New Customer'] {
  display: none;
  position: absolute;
  z-index: 9999;
  right: 17px;
  top: 97px; }

.closeForm {
  margin-top: 0px;
  position: absolute;
  right: 10px; }

.tab-pane.active.EditContract .closeForm,
.tab-pane.active.EditContract .btn.btn-sm.btn-success.AddContract {
  display: none; }

td {
  max-width: 420px; }

tr.overdue {
  background: #fbe5e1; }

div#contract-finish .btn.btn-danger.btn-sm {
  display: none; }

div#contract-finish .photo_upload [name='img_damage_report_photo_id'] {
  display: none !important; }

button.btn.btn-sm.btn-success.newContract {
  margin-top: 0px !important; }

.board.contract .tgt-form .form-footer {
  float: left !important; }

.clearfilter.BikeCheckin {
  margin-top: 18px !important; }

.datepicker-days td:first-child {
  background: #dd4b39 !important;
  color: white !important; }

.datepicker-days td:first-child:hover {
  color: #000 !important; }

div.form-footer {
  clear: both; }

.removeborder {
  border: none !important; }

button.btn.btn-sm.btn-success.checkMotor {
  margin-top: 0px; }

form.tgt-form.ContratRequest.row.contract {
  max-width: 93%;
  float: left; }

main.content .dataTables_wrapper {
  border: 1px solid #ececec;
  padding: 10px;
  float: left;
  width: 100%; }

.right {
  float: right !important; }

form.tgt-form.Contract.Workshop.filter.col-sm-10 {
  padding-left: 12px; }

.Workshop.filter.col-sm-2 button.btn.btn-primary.btn-sm.clearfilter {
  float: right;
  margin-right: 15px; }

.Workshop button.btn.btn-primary.btn-sm {
  margin-top: 20px !important; }

.displaynone {
  display: none !important; }

.table-hover > tbody > tr.overdue:hover {
  background: #fbe5e1 !important; }

tr.priority {
  background: #dd4b39 !important; }

.PartsBtn .btn:nth-child(1) {
  margin-right: 10px; }

.bike-location-report .row-block h4.no_checkin {
  background: #fb9f95; }

.bike-location-report .row-block .bike-item .btn-sm-view {
  margin-right: 3px; }

.btn.btn-sm.btn-success.redirectContract,
.btn.btn-sm.btn-success.redirectContract1 {
  display: none; }

.btn.btn-sm.btn-primary.clear.right {
  margin-top: 5px; }

.btn.btn-sm.btn-envelope {
  background: #f39c12;
  color: #f4f4f4; }

.box.report-page .tab-content > .active {
  display: block;
  overflow: auto; }

main.content .dataTables_wrapper {
  overflow: auto; }

.tgt-datatable td {
  word-wrap: break-word; }

.contacted,
.table-hover > tbody > tr.contacted:hover {
  background: #ffc05b !important; }

.responded,
.table-hover > tbody > tr.responded:hover {
  background: #098009 !important;
  color: #ffffff; }

.table-hover > tbody > tr.responded a {
  color: #ffffff !important; }

.btn.btn-sm.btn-success.backbtn {
  margin-bottom: 10px; }

.btn {
  margin-bottom: 5px; }

i.fa.fa-check.white {
  color: #ffffff; }

.btn.btn-sm.btn-success.newCity {
  margin-right: 10px; }

.col-lg-12.col-sm-12.commmunication-popup-header {
  padding-top: 10px;
  padding-bottom: 10px; }

h4.field_heading {
  border-bottom: solid 1px;
  padding-bottom: 10px;
  font-weight: bold;
  max-width: 250px; }

.td_hide {
  display: none; }

td.hidden_time span {
  display: none; }

.btn-sm.group-submit {
  margin-top: 23px; }

div#mdl-bikes-form .modal-body {
  overflow: hidden; }

.print.align-right {
  width: 100%;
  float: left;
  text-align: right;
  padding-top: 4px;
  position: absolute;
  right: 1%; }
  .print.align-right i {
    color: #337ab7;
    cursor: pointer; }
    .print.align-right i:hover {
      color: #23527c; }

h4.print_heading {
  text-align: center;
  padding-top: 45px; }

p.print_heading_text {
  text-align: center; }

.bank-info,
.customer-info,
.payment-info,
.payment-accessries-info {
  margin: 0px 25px 0px 25px;
  overflow: hidden;
  padding-top: 25px; }

.term-info {
  margin: 0px 25px 0px 25px;
  overflow: hidden; }

p.main_heading {
  font-size: 16px;
  line-height: 0; }

p.sub_heading {
  font-size: 13px;
  border-bottom: #000 solid;
  padding-bottom: 5px;
  margin-bottom: 25px; }

.input_heading {
  font-size: 14px; }

.input_subheading {
  font-size: 13px;
  padding-bottom: 5px; }

.print-container {
  width: 100%;
  padding-bottom: 100px;
  color: #000; }

.remove_padding {
  padding: 0px; }

p.field_value {
  border-bottom: dotted;
  border-width: 1px;
  font-size: 13px;
  text-align: left;
  margin-left: 10px;
  padding: 0px;
  line-height: 2;
  max-width: 220px; }

.single_line p.field_value {
  padding-top: 20px;
  max-width: 634px; }

.payment-info p.field_value,
.payment-accessries-info p.field_value {
  float: right;
  width: 75%;
  margin-right: 15px; }

p.input_heading.total {
  font-size: 15px;
  font-weight: bold;
  text-decoration: underline; }

.term-info span {
  font-weight: bold; }

.customer_signature {
  max-width: 350px;
  margin: 0 auto;
  margin-top: 70px; }

.term-info p.field_value {
  float: right;
  overflow: hidden;
  width: 350px;
  padding-top: 20px; }

span.active_contracts {
  float: left;
  margin-bottom: 5px;
  width: 100%; }

span.active_contracts .Contract {
  margin-right: 20px; }

.Graph_container {
  margin-bottom: 50px;
  max-height: 300px; }

.Graph_container h4 {
  text-decoration: underline; }

td.mannual_charge i.fa.fa-check.white {
  color: #058605; }

.btn.btn-sm.btn-payment {
  background-color: #337ab7;
  color: #fff; }

.btn.btn-sm.btn-btn.btn-sm.btn-note {
  background: #008d4c;
  color: #ffff; }

input[name='cost_duration_extended'],
input[name='cost_duration_base'] {
  max-width: 255px; }

.board.contract-request .nav-tabs > li {
  width: calc(100% / 4) !important; }

.board.contractTab-request .nav-tabs > li {
  width: calc(100% / 8) !important; }

ul.action-menu {
  list-style-type: none;
  padding: 0;
  border: #e4e2e2 solid 1px;
  border-radius: 2px;
  color: gray;
  background-color: #fff;
  position: absolute;
  width: 210px;
  right: 30px;
  margin-top: 35px;
  z-index: 999;
  text-align: left;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease; }
  ul.action-menu .btn {
    width: 40px;
    margin-right: 10px;
    margin-bottom: 0; }
  ul.action-menu li {
    padding: 6px 15px;
    border-bottom: 1px solid #e6dcdc;
    cursor: pointer; }
    ul.action-menu li:hover {
      background: #e4e4e4; }
  ul.action-menu.active {
    opacity: 1;
    pointer-events: auto; }

.box.contracts .tgt-table-container {
  min-height: 600px; }

i.fa.fa-ellipsis-v {
  font-size: 30px;
  border-radius: 50%;
  color: gray;
  margin-left: 20px; }

div.bike_column th,
div.bike_column td {
  padding: 6px 10px;
  border: #dedede solid 1px;
  border-top: none;
  min-width: 435px; }

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 999; }

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px; }

#crequest-transport .hidden {
  display: inline-block !important;
  width: 50%;
  overflow: hidden;
  float: left; }

#crequest-transport .hidden div {
  display: none; }

#crequest-transport .form-group.hidden {
  display: none !important; }

#crequestTab-transport .damage_waiver_content {
  float: right; }

.errorBarsSelect {
  max-width: 250px; }

.btn.btn-sm.btn-btn.btn-sm.btn-note:hover {
  background: #006d3b; }

.btn.btn-sm.btn-envelope:hover {
  background: #cc8009; }

@-webkit-keyframes chartfix {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  50% {
    transform: scale(1.01);
    -webkit-transform: scale(1.01); }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

@keyframes chartfix {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  50% {
    transform: scale(1.01);
    -webkit-transform: scale(1.01); }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

.panel {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
  animation: chartfix 5s;
  -webkit-animation: chartfix 5s; }

.stock-prediction-chart.interactive-legend .recharts-legend-item {
  cursor: pointer; }

.stock-prediction-chart .recharts-legend-item .disabled {
  text-decoration: line-through; }

.stock-prediction-chart .recharts-tooltip-wrapper {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px; }
  .stock-prediction-chart .recharts-tooltip-wrapper table th,
  .stock-prediction-chart .recharts-tooltip-wrapper table td {
    padding: 0 2px;
    font-size: 12px; }
  .stock-prediction-chart .recharts-tooltip-wrapper table th {
    font-size: 10px; }
    .stock-prediction-chart .recharts-tooltip-wrapper table th[colspan='2'] {
      text-align: center; }
  @supports ((-webkit-backdrop-filter: blur(4px)) or (backdrop-filter: blur(4px))) {
    .stock-prediction-chart .recharts-tooltip-wrapper {
      -webkit-backdrop-filter: blur(4px);
              backdrop-filter: blur(4px); } }

.stock-prediction-chart .recharts-errorBar line:first-child,
.stock-prediction-chart .recharts-errorBar line:last-child {
  stroke-dasharray: none; }

.bike-lookup textarea {
  max-width: 100%;
  min-width: 100%;
  height: 200px;
  margin-bottom: 10px; }

.bike-lookup .table > tbody > tr > td {
  vertical-align: middle; }
  .bike-lookup .table > tbody > tr > td .btn:not(:first-child) {
    margin-left: 5px; }

.bike-lookup .loading-container {
  display: flex;
  align-items: center;
  justify-content: center; }

.btn.loading {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  box-shadow: none;
  opacity: 0.65;
  pointer-events: none; }
  .btn.loading .fa {
    border: 2px solid #f1f1f1;
    border-top: 2px solid transparent;
    width: 11px;
    height: 11px; }
    .btn.loading .fa:before {
      content: ''; }
  .btn.loading.btn-default .fa {
    border: 2px solid #555;
    border-top: 2px solid transparent; }

.text-bold {
  font-weight: bold; }

.icheck-like label {
  position: relative;
  padding-left: 21px; }
  .icheck-like label input {
    display: none; }
  .icheck-like label .styled-checkbox {
    position: absolute;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #dadada;
    -webkit-transition: border-color 0.25s;
    transition: border-color 0.25s; }
    .icheck-like label .styled-checkbox:before, .icheck-like label .styled-checkbox:after {
      font: normal normal normal 14px/1 FontAwesome;
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      padding: 1px;
      color: transparent;
      -webkit-transition: 0.25s;
      transition: 0.25s;
      -webkit-transition-property: color, -webkit-transform;
      transition-property: color, -webkit-transform;
      transition-property: color, transform;
      transition-property: color, transform, -webkit-transform; }
    .icheck-like label .styled-checkbox:before {
      content: '\F068';
      font-size: 12px;
      padding: 3px; }
    .icheck-like label .styled-checkbox:after {
      content: '\F00C'; }
  .icheck-like label:hover input:not(:checked):not(:indeterminate):not(:disabled) + .styled-checkbox {
    border-color: #78a300; }
  .icheck-like label input:indeterminate + .styled-checkbox:before,
  .icheck-like label input:checked + .styled-checkbox:after {
    color: #78a300;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }

.icheck-like.disabled label {
  color: #777;
  font-style: italic;
  cursor: not-allowed; }
  .icheck-like.disabled label .styled-checkbox {
    background-color: #eee; }

.tgt-table {
  border: 1px solid #ececec;
  padding: 10px;
  display: flex;
  flex-direction: column; }
  .tgt-table .box-action {
    width: auto;
    position: relative; }
  .tgt-table .tgt-table-container {
    flex: 1 1;
    max-height: 80vh;
    min-height: 39px;
    overflow: auto;
    padding-bottom: 1px; }
    .tgt-table .tgt-table-container table {
      margin-bottom: 0; }
      .tgt-table .tgt-table-container table thead th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000;
        background-color: white; }
        .tgt-table .tgt-table-container table thead th:before, .tgt-table .tgt-table-container table thead th:after {
          content: '';
          position: absolute;
          height: 2px;
          left: 0;
          right: 0;
          background-color: #f4f4f4; }
        .tgt-table .tgt-table-container table thead th:before {
          top: -2px; }
        .tgt-table .tgt-table-container table thead th:after {
          bottom: -2px; }
        .tgt-table .tgt-table-container table thead th.tgt-table--sortable-column {
          padding-right: 30px;
          cursor: pointer; }
          .tgt-table .tgt-table-container table thead th.tgt-table--sortable-column .tgt-table--sort-icon {
            display: contents; }
            .tgt-table .tgt-table-container table thead th.tgt-table--sortable-column .tgt-table--sort-icon:after {
              position: absolute;
              content: '\E150';
              opacity: 0.2;
              -webkit-transition: opacity 0.25s ease;
              transition: opacity 0.25s ease;
              top: 50%;
              -webkit-transform: translateY(-50%);
                      transform: translateY(-50%);
              right: 8px;
              font-family: Glyphicons Halflings; }
          .tgt-table .tgt-table-container table thead th.tgt-table--sortable-column[aria-sort='ascending'] .tgt-table--sort-icon:after {
            content: '\E155'; }
          .tgt-table .tgt-table-container table thead th.tgt-table--sortable-column[aria-sort='descending'] .tgt-table--sort-icon:after {
            content: '\E156'; }
          .tgt-table .tgt-table-container table thead th.tgt-table--sortable-column[aria-sort='ascending'] .tgt-table--sort-icon:after,
          .tgt-table .tgt-table-container table thead th.tgt-table--sortable-column[aria-sort='descending'] .tgt-table--sort-icon:after {
            opacity: 0.5; }
          .tgt-table .tgt-table-container table thead th.tgt-table--sortable-column:hover .tgt-table--sort-icon:after {
            opacity: 0.4; }
          .tgt-table .tgt-table-container table thead th.tgt-table--sortable-column:hover .tgt-table--sort-icon[aria-sort='ascending']:after, .tgt-table .tgt-table-container table thead th.tgt-table--sortable-column:hover .tgt-table--sort-icon[aria-sort='descending']:after {
            opacity: 0.7; }
      .tgt-table .tgt-table-container table td .form-group {
        margin: 0; }
  .tgt-table > .loading {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
    .tgt-table > .loading:after {
      content: ''; }
  .tgt-table .tgt-table--controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px; }
    .tgt-table .tgt-table--controls .tgt-table--record-count {
      font-style: italic;
      padding-left: 2px; }
    .tgt-table .tgt-table--controls .tgt-table--search {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      font-weight: 400;
      margin-bottom: 0; }
      .tgt-table .tgt-table--controls .tgt-table--search input {
        border: 1px solid #eeeeee;
        margin-left: 0.5em;
        height: 30px;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5; }

.sidebar-toggle {
  outline: none;
  border: none; }

.CalendarDay.CalendarDay__today {
  position: relative; }
  .CalendarDay.CalendarDay__today:after {
    content: '';
    position: absolute;
    border: 8px solid #8bc34a;
    right: 0;
    top: 0;
    border-left-color: transparent;
    border-bottom-color: transparent; }

.CalendarDay.CalendarDay__firstDayOfWeek {
  position: relative; }
  .CalendarDay.CalendarDay__firstDayOfWeek:before {
    content: '';
    position: absolute;
    border: 8px solid #dd4b39;
    left: 0;
    top: 0;
    border-right-color: transparent;
    border-bottom-color: transparent; }

textarea.form-control {
  max-width: 100%; }

.tgt-form.tgt-form--filter {
  border: none;
  padding: 5px 10px; }
  .tgt-form.tgt-form--filter .form-group {
    float: left;
    padding: 0;
    max-width: calc(100% - 10px);
    min-width: 100%; }
    @media (min-width: 768px) {
      .tgt-form.tgt-form--filter .form-group {
        min-width: calc(3 / 12 * 100% - 10px); }
        .tgt-form.tgt-form--filter .form-group.wide {
          min-width: 400px; } }
    @media (min-width: 1200px) {
      .tgt-form.tgt-form--filter .form-group {
        min-width: calc(2 / 12 * 100% - 10px); } }
    .tgt-form.tgt-form--filter .form-group > label {
      font-weight: 600; }
  .tgt-form.tgt-form--filter .form-group,
  .tgt-form.tgt-form--filter .tgt-form--filter--action {
    margin: 5px; }
  .tgt-form.tgt-form--filter .tgt-form--filter--action {
    float: left; }
    @media (min-width: 768px) {
      .tgt-form.tgt-form--filter .tgt-form--filter--action {
        margin-top: 32px; } }
  .tgt-form.tgt-form--filter .date-range-picker {
    width: auto; }

.request-checkout-email-field {
  display: flex;
  align-items: flex-start; }
  .request-checkout-email-field .form-group {
    flex: 1 1;
    margin-right: 5px; }
  .request-checkout-email-field .btn {
    margin-top: 25px; }

.tgt-form--filter .form-group.date-picker .SingleDatePicker,
.tgt-form--filter .form-group.date-picker .DateRangePicker {
  z-index: 1001; }

.form-group.date-picker {
  max-width: 100%; }
  .form-group.date-picker .SingleDatePicker,
  .form-group.date-picker .DateRangePicker {
    display: flex;
    align-items: center; }
    .form-group.date-picker .SingleDatePicker > div,
    .form-group.date-picker .DateRangePicker > div {
      display: contents; }
      .form-group.date-picker .SingleDatePicker > div .SingleDatePickerInput,
      .form-group.date-picker .SingleDatePicker > div .DateRangePickerInput,
      .form-group.date-picker .DateRangePicker > div .SingleDatePickerInput,
      .form-group.date-picker .DateRangePicker > div .DateRangePickerInput {
        display: flex;
        align-items: center;
        flex: 1 1;
        border-radius: 4px; }
        .form-group.date-picker .SingleDatePicker > div .SingleDatePickerInput.DateRangePickerInput__showClearDates,
        .form-group.date-picker .SingleDatePicker > div .DateRangePickerInput.DateRangePickerInput__showClearDates,
        .form-group.date-picker .DateRangePicker > div .SingleDatePickerInput.DateRangePickerInput__showClearDates,
        .form-group.date-picker .DateRangePicker > div .DateRangePickerInput.DateRangePickerInput__showClearDates {
          padding-right: 34px; }
      .form-group.date-picker .SingleDatePicker > div .DateInput,
      .form-group.date-picker .DateRangePicker > div .DateInput {
        width: 88px;
        flex: 1 1;
        background-color: transparent; }
        .form-group.date-picker .SingleDatePicker > div .DateInput .DateInput_input,
        .form-group.date-picker .DateRangePicker > div .DateInput .DateInput_input {
          padding: 3px 10px;
          background-color: transparent;
          font-size: 14px;
          font-weight: 400; }
          .form-group.date-picker .SingleDatePicker > div .DateInput .DateInput_input::-webkit-input-placeholder,
          .form-group.date-picker .DateRangePicker > div .DateInput .DateInput_input::-webkit-input-placeholder {
            font-weight: 200; }
          .form-group.date-picker .SingleDatePicker > div .DateInput .DateInput_input::-moz-placeholder,
          .form-group.date-picker .DateRangePicker > div .DateInput .DateInput_input::-moz-placeholder {
            font-weight: 200; }
          .form-group.date-picker .SingleDatePicker > div .DateInput .DateInput_input:-ms-input-placeholder,
          .form-group.date-picker .DateRangePicker > div .DateInput .DateInput_input:-ms-input-placeholder {
            font-weight: 200; }
          .form-group.date-picker .SingleDatePicker > div .DateInput .DateInput_input::-ms-input-placeholder,
          .form-group.date-picker .DateRangePicker > div .DateInput .DateInput_input::-ms-input-placeholder {
            font-weight: 200; }
          .form-group.date-picker .SingleDatePicker > div .DateInput .DateInput_input::placeholder,
          .form-group.date-picker .DateRangePicker > div .DateInput .DateInput_input::placeholder {
            font-weight: 200; }
      .form-group.date-picker .SingleDatePicker > div .SingleDatePickerInput_clearDate,
      .form-group.date-picker .SingleDatePicker > div .DateRangePickerInput_clearDates,
      .form-group.date-picker .DateRangePicker > div .SingleDatePickerInput_clearDate,
      .form-group.date-picker .DateRangePicker > div .DateRangePickerInput_clearDates {
        padding: 0;
        margin: 0;
        right: 2px;
        -webkit-transform: none;
                transform: none;
        top: 2px;
        height: 30px;
        width: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center; }
        .form-group.date-picker .SingleDatePicker > div .SingleDatePickerInput_clearDate svg,
        .form-group.date-picker .SingleDatePicker > div .DateRangePickerInput_clearDates svg,
        .form-group.date-picker .DateRangePicker > div .SingleDatePickerInput_clearDate svg,
        .form-group.date-picker .DateRangePicker > div .DateRangePickerInput_clearDates svg {
          width: 14px; }

.react-select-container .react-select__menu {
  z-index: 1001; }

.react-select-container .react-select__control {
  min-height: 34px;
  cursor: pointer; }
  .react-select-container .react-select__control .react-select__indicator {
    padding: 4px 8px; }

.react-select-container .react-select__option--is-disabled.react-select__option--is-focused {
  background-color: transparent; }

.tgt-table td .location-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px; }

.nowrap {
  white-space: nowrap; }

.contract-request-bikes {
  white-space: nowrap;
  margin-bottom: 10px; }
  .contract-request-bikes .btn {
    margin-left: 5px; }

.bike-type-add-form {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px; }
  .bike-type-add-form .bike-type-add-form--bike-type {
    order: 1; }
  .bike-type-add-form .bike-type-add-form--quantity {
    order: 2;
    flex: 1 1; }
  .bike-type-add-form .bike-type-add-form--submit {
    margin-top: 25px;
    margin-left: 5px;
    order: 3; }
  .bike-type-add-form .bike-type-add-form--bike-type {
    width: 100%; }
  .bike-type-add-form .bike-type-add-form--bike {
    flex: 1 1;
    width: auto; }
  @media (min-width: 768px) {
    .bike-type-add-form {
      flex-wrap: nowrap; }
      .bike-type-add-form .bike-type-add-form--quantity {
        flex: none;
        margin-right: 5px;
        order: 1; }
        .bike-type-add-form .bike-type-add-form--quantity input {
          width: 80px; }
      .bike-type-add-form .bike-type-add-form--bike-type {
        width: 300px;
        order: 2; } }

.tgt-form .table td .form-group.checkbox {
  margin: 0; }

.box.--contains-table .box-body .box-block,
.box.--contains-table .box-body .tab-content,
.box.--contains-table .box-body .tab-content > .active {
  display: contents; }

.login-box {
  border: 1px solid #ccc; }
  .login-box .login-logo {
    background: #087b83;
    display: flex;
    align-items: center;
    justify-content: center; }
    .login-box .login-logo svg {
      width: 100px;
      margin: 10px;
      vertical-align: middle; }

.box-header.box-subheader {
  padding-bottom: 0; }

