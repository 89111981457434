$primary-color: #057985;
$primary-color-hov: #046b75;
$primary-logo-hov: #035c65;

@mixin media-max($screen) {
  @media only screen and (max-width: $screen) {
    @content;
  }
}
@mixin media-min($screen) {
  @media only screen and (min-width: $screen) {
    @content;
  }
}
