/* written by riliwan balogun http://www.facebook.com/riliwan.rabo*/
.board {
  width: 100%;
  margin: 0;
  height: auto;
  background: #fff;
  .nav-tabs {
    position: relative;
    border-color: #e08e0b;
    margin-bottom: 0;
    box-sizing: border-box;
    a[data-toggle='tab'].disabled {
      cursor: not-allowed;
    }
  }

  &.board-flex .nav-tabs {
    display: flex;
    width: 100%;

    > li {
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }

  .board-inner > .alert {
    margin-top: 10px;
    margin-bottom: 0;
  }

  p.narrow {
    width: 60%;
    margin: 10px auto;
  }
  .nav-tabs > li {
    &:before {
      content: '';
      height: 2px;
      background: #ddd;
      position: absolute;
      width: 100%;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 40%;
      z-index: 1;
    }

    &:first-of-type:before {
      width: 50%;
      left: 50%;
    }

    &:last-of-type:before {
      width: 50%;
      right: 50%;
    }

    &:first-of-type:last-of-type:before {
      width: 0;
    }
  }

  .nav-tabs > li > button {
    position: relative;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;

    > .round-tabs {
      top: 0;
    }
  }

  .nav-tabs > li.active > button,
  .nav-tabs > li.active > button:hover,
  .nav-tabs > li.active > button:focus,
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    color: #555555;
    cursor: default;
    /* background-color: #ffffff; */
    border: 0;
    border-bottom-color: transparent;
  }
  span.round-tabs {
    width: 70px;
    height: 70px;
    line-height: 70px;
    display: inline-block;
    border-radius: 100px;
    background: white;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 25px;
    color: #ccc;
    border: 2px solid #ccc;
    transition: 250ms ease-in-out;
    transition-property: color, background-color, border-color;
  }

  li.invalid span.round-tabs {
    border-color: #dd4b39;
    color: #dd4b39;
  }

  li.active span.round-tabs {
    border-color: #e08e0b;
    color: #e08e0b;
    background-color: #fafafa;
  }

  .nav-tabs > li {
    width: 20%;
  }

  .nav-tabs > li:after {
    content: ' ';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: #e08e0b;
    transition: 250ms ease-in-out;
    transition-property: opacity, border-width;
  }
  .nav-tabs > li.active:after {
    opacity: 1;
    border-width: 10px;
  }
  .nav-tabs > li button,
  .nav-tabs > li a {
    width: 70px;
    height: 70px;
    margin: 20px auto;
    margin-top: 0px;
    border-radius: 100%;
    padding: 0;
  }

  .nav-tabs > li button:hover,
  .nav-tabs > li a:hover {
    background: transparent;
  }

  .tab-pane {
    position: relative;
    padding-top: 10px;
  }
  .tab-content .head {
    font-size: 25px;
    text-transform: uppercase;
    padding-bottom: 10px;
  }
  .btn-outline-rounded {
    padding: 10px 40px;
    margin: 20px 0;
    border: 2px solid transparent;
    border-radius: 25px;
  }

  .btn.green {
    background-color: #5cb85c;
    /*border: 2px solid #5cb85c;*/
    color: #ffffff;
  }

  @media (max-width: 585px) {
    width: 90%;
    height: auto !important;
    span.round-tabs {
      font-size: 16px;
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
    .tab-content .head {
      font-size: 20px;
    }
    .nav-tabs > li button,
    .nav-tabs > li a {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }

    .btn-outline-rounded {
      padding: 12px 20px;
    }
  }
}
