.tgt-loader {
  border: 5px solid #f1f1f1;
  border-radius: 100%;
  border-top: 5px solid $primary-color;
  width: 35px;
  height: 35px;
  display: inline-block;
  -webkit-animation: tgt-spin 0.8s linear infinite;
  animation: tgt-spin 0.8s linear infinite;
}
@-webkit-keyframes tgt-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes tgt-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tab-loader {
  text-align: center;
  margin: 10px 0px;
  padding: 50px 0px;
}
